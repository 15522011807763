@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Rubik:400,700");

#body-menu-left {
    width: 330px;
    height: 92vh;
    top: -20px;
    margin-bottom: 20px;
    /*margin-left: 20px;*/
    /*-webkit-border-radius: 15px;*/
    /*border-radius: 15px;*/
    background-image: -webkit-linear-gradient(220deg, #003474, #00c1f5);
    background-image: -o-linear-gradient(220deg, #003474, #00c1f5);
    background-image: -webkit-linear-gradient(230deg, #003474, #00c1f5);
    background-image: -o-linear-gradient(230deg, #003474, #00c1f5);
    background-image: linear-gradient(220deg, #003474, #0c1a35);
    z-index: 80;
    position: fixed;
    -webkit-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    overflow-y: auto;
    overflow-x: hidden;
}

.title-center-home h2 {
    color: #FFFFFF;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: 5px;
    padding: 15px 7px 15px 7px;
    margin-top: 20px;
    border-bottom: 1px solid #0083aa;
    border-top: 1px solid  #0083aa;
}

.container-data-site {
    display: grid;
    text-align: center;
    justify-items: center;
    padding: 10px;
    min-height: 100px;
}


/*propiedades de info del sitio*/
.container-data-site img {
    width: 100px;
    margin-top: 5%;
    margin-bottom: 5%;
    -webkit-background-size: cover;
    background-size: cover;
}

.date-site h1 {
    font-size: 15px;
    margin-top: 13px;
    width: 200px;
}

.date-site h3, h4 {
    color: white;
    font-size: 13px;
    font-weight: 100;
    margin-bottom: 10px;
}

/*css boton de editar perfil */
.Btn-edit-profile {
    color: white;
    margin-left: 30px;
    margin-top: 10px;
    margin-bottom: 30px;
}

/*fin propiedades de info del sitio*/
/*menus de navegacion principal*/
.container-data-general h2 {
    color: white;
    font-size: 16px;
}
.Content-General {
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
}

.icono1 img {
    width: 45px;
    margin-top: 5px;
    margin-left: 20px;
}

.icono2 {
    width: 35px;
    height: 20px;
    margin-top: 15px;
    cursor: pointer;
}

#window-Content-General li {
    padding: 10px;
    margin-left: 20px;
    list-style: none;
}

#window-Content-General a {
    color: white;
    font-size: 15px;
}

.styles {
    margin-top: 8px;
    width: 40px;
    height: 39px;
    background: none;
    color: white;
    font-size: 15px;
    outline: none;
    border: none;
    cursor: pointer;
    padding: 5px;
    text-align: left;
}
.styles3 {
    width: 350px;
    font-weight: 600;
    background: none;
    color: white;
    border: none;
    text-align: left;
}

#target2, #target3, #target4, #target5, #target6, #target7, #target8 {
    display: none;
    border-bottom: 1px solid #0086a5;
    border-top: 1px solid #0086a5;
}
.styles3:hover {
    color: #00c1f5;

}

#target2 ul, #target3 ul, #target4 ul, #target5 ul, #target6 ul, #target7 ul, #target8 ul {
    background-color: #124e8e;
    color: white;
    list-style: square;
    display: grid;
    justify-items: left;
}

#target2 li, #target3 li, #target4 li, #target5 li, #target6 li, #target7 li, #target8 li {
    padding: 8px;
    margin-left: 60px;
}

#target2 li a, #target3 li a, #target4 li a, #target5 li a, #target6 li a, #target7 li a, #target8 li a {
    text-decoration: none;
    color: #FFFFFF;
    font-size: 15px;
    cursor: pointer;
}

@media screen and (min-width: 760px) {
    #body-menu-left {
        /*-webkit-box-shadow: 0 0 8px #4a4a49, 0 0 5px #4a4a49;*/
        /*box-shadow: 0 0 8px #4a4a49, 0 0 5px #4a4a49;*/
    }
}