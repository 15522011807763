
@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Rubik:400,700");



/*******************/
.btn-container {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 38px;
    grid-gap: 10px;
    width: 100%; }

.Btn-modal {
    width: 100%;
    padding: 10px;
    font-size: 13px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    text-decoration: none;
    color: #FFFFFF;
    text-align: center;
    background: #1d90ff;
    border: 1.4px solid #1d90ff; }
.Btn-modal:hover {
    color: #FFFFFF;
    background: #261820;
    border: 1.4px solid #261820; }

/*******************/

/*Estilos btn buscar*/
.Btn-buscar {
    width: 100%;
    padding: 10px;
    font-size: 13px;
    -webkit-border-radius: 5px;
    border-radius:5px;
    text-decoration: none;
    color: #FFFFFF;
    text-align: center;
    background: #003474;
    border: 1.4px solid #003474; }

.Btn-buscar:hover {
    color: #FFFFFF;
    background: #ff1826;
    border: 1.4px solid #ff0817; }

/*******************/
.Btn-general-filter {
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    grid-gap: 15px;
    margin-bottom: 20px; }

.container-add-direction {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px; }
.container-add-direction2 {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 10px; }

.div-btn-direction {
    display: grid;
    grid-gap: 10px;
    width: 100%;
    grid-template-columns: 1fr 1fr; }

/*Estilos btn Guardar*/
.Btn-general-direction {
    height: 37px;
    padding: 10px;
    min-width: 100px;
    font-size: 13px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    text-decoration: none;
    color: #3c424f;
    text-align: center;
    border: 1.4px solid #a8a8a8; }

.Btn-general-direction:hover {
    color: #FFFFFF;
    background: #261820;
    border: 1.4px solid #261820; }


/*******************/
.Btn-general-add {
    width: 100%;
    height: 37px;
    min-width: 127px;
    padding: 10px;
    font-size: 13px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    text-decoration: none;
    color: #3c424f;
    text-align: center;
    border: 1.4px solid #a8a8a8; }

.Btn-general-add:hover {
    color: #FFFFFF;
    background: #261820;
    border: 1.4px solid #261820; }


/*-Estilos btn enviar tabla*/
.Btn-item-table {
    width: 109px;
    min-width: 40px;
    height: 38px;
    font-size: 13px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    text-align: center;
    background: #1d90ff;
    border: 1.4px solid #1d90ff;
    overflow: hidden;
}

.Btn-item-tabla:hover {
    background: #001a39;
    border: 1.4px solid #001a39;
}

.Btn-item2-table {
    width: 109px;
    min-width: 40px;
    height: 38px;
    font-size: 13px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    text-align: center;
    background: #ea9747;
    border: 1.4px solid #ea9747;
}

.Btn-item2-tabla:hover {
    background: #001a39;
    border: 1.4px solid #001a39;
}
.Btn-item-eliminar-tabla {
    width: 60px;
    min-width: 40px;
    height: 40px;
    font-size: 13px;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
    -webkit-border-radius: 5px;
    border-radius: 5px;
    text-decoration: none;
    color: white;
    text-align: center;
    background: #e43e51;
    border: 1.4px solid #e43e51; }

.Btn-general-eliminar-tabla:hover {
    background: #576f89;
    border: 1.4px solid #576f89; }
/*******************/
@media screen and (min-width: 760px) {
    .Btn-general {
        justify-items: right; }
    .Btn-general-filter {
        grid-template-columns: 1fr; }
    .container-add-direction {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 10px; }
    .container-add-direction2 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px; }
    .btn-general-only {
        grid-template-columns: 1fr 1fr 1fr; }
    .div-btn-add2 {
        display: grid;
        grid-gap: 10px;
        grid-template-columns: 100%; }
    .Btn-general-config {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        justify-items: center; }
    .Btn-buscar {
        width: 200px; }
    .div-btn-filter {
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 10px;
        width: 100%; } }

@media screen and (min-width: 1024px) {
    .Btn-general-filter {
        grid-template-columns:1fr; }
    .container-add-direction {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-gap: 10px; }
    .container-add-direction2 {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 10px; }}
